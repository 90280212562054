<template>
  <section class="depot__table-wrapper">
    <teleport v-if="state.planList?.length" to="#header__title">
      {{ state.planList[0]?.deal_title }}
    </teleport>

    <div class="d-flex align-items-start">
      <div v-if="!isSupervisor" class="depot__filters-wrapper">
        <SearchFilter v-model="state.searchFilter" />

        <div class="depot__filters">
          <div class="d-flex align-items-center justify-content-between mb-4">
            <h4 class="depot__filters-title">Фильтр</h4>
            <button
              type="button"
              class="depot__filters-reset"
              @mousedown="resetFilters"
            >
              Очистить все
            </button>
          </div>

          <span class="line-separator"></span>

          <h5 class="depot__filters-subtitle">Цех</h5>
          <v-select
            class="depot__select"
            placeholder="Все цеха"
            label="title"
            :options="state.manufactureList"
            :reduce="(item) => item.id"
            v-model="state.manufactureFilterId"
            ><template #option="option">
              <span
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                :title="option.title"
              >
                {{ option.title }}
              </span>
            </template></v-select
          >

          <h5 class="depot__filters-subtitle">Участки</h5>
          <v-select
            class="depot__select"
            placeholder="Все участки"
            label="title"
            :options="state.areaList"
            :reduce="(item) => item.id"
            :disabled="!state.manufactureFilterId"
            v-model="state.areaFilterIds"
            multiple
          >
            <template #option="option">
              <span
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                :title="option.title"
              >
                {{ option.title }}
              </span>
            </template>
            <template v-slot:no-options>Ничего не найдено.</template>
          </v-select>
        </div>
      </div>

      <SubtasksTable
        :class="{ 'application-worker': isSupervisor }"
        v-model:loading="isDataLoading"
        v-model:productToShow="productToShow"
        :planList="state.planList"
        :refetch="getData"
      />
    </div>

    <Pagination
      v-model:currentPage="state.currentPage"
      :totalPages="state.totalPages"
    />
  </section>

  <PlanMaterialModal :productId="productToShow" :apiRequest="getPlanProducts" />
</template>

<script>
import { ref, reactive, watch, onMounted, nextTick } from "vue";
import { useRoute } from "vue-router";

import useLoadingModal from "@/mixins/useLoadingModal";
import { registerDropdown } from "@/helpers/bootstrap";
import { errorAlert } from "@/helpers/alerts";
import { getSubtasks, getPlanProducts } from "@/api/plan";
import {
  getManufactureList,
  getManufactureAreaList as fetchManufactureAreaList,
} from "@/api/manufacture";

import SubtasksTable from "@/components/plan/SubtasksTable.vue";
import PlanMaterialModal from "@/components/plan/MaterialModal.vue";
import SearchFilter from "@/components/depot/SearchFilter.vue";
import Pagination from "@/components/ui/Pagination.vue";

export default {
  name: "plan-page",
  components: { SubtasksTable, SearchFilter, Pagination, PlanMaterialModal },
  props: ["isSupervisor"],
  setup(props) {
    const route = useRoute();
    const state = reactive({
      task_id: route.params.taskid,
      subtask_id: route.params.id,
      loading: false,
      currentPage: 1,
      totalPages: 1,
      planList: [],
      searchFilter: null,
      statusFilter: props.isSupervisor ? 1 : null,
      manufactureList: [],
      manufactureFilterId: null,
      areaFilterIds: [],
      areaList: [],
      isAreaListLoading: false,
    });
    const { isDataLoading } = useLoadingModal();
    const productToShow = ref(null);

    const resetFilters = () => {
      state.manufactureFilterId = null;
      state.searchFilter = null;
      state.areaFilterIds = [];
    };

    onMounted(async () => {
      getData();

      try {
        state.manufactureList = await getManufactureList();
      } catch {
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      }
    });

    watch(
      () => state.manufactureFilterId,
      () => {
        state.areaList = [];
        if (state.areaFilterIds.length != 0) state.areaFilterIds = [];
        else if (state.manufactureFilterId == null) return;
        else getManufactureAreaList();
      }
    );

    watch(
      () => [
        state.manufactureFilterId,
        state.searchFilter,
        state.areaFilterIds,
      ],
      () => {
        if (state.currentPage != 1) state.currentPage = 1;
        else getData();
      }
    );

    watch(
      () => state.currentPage,
      () => {
        getData();
      }
    );

    async function getManufactureAreaList() {
      try {
        state.isAreaListLoading = true;
        state.areaList = await fetchManufactureAreaList(
          state.manufactureFilterId
        );
      } catch {
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      } finally {
        state.isAreaListLoading = false;
      }
    }

    async function getData() {
      isDataLoading.value = true;

      try {
        const res = await getSubtasks(
          state.task_id,
          state.subtask_id,
          state.currentPage,
          state.areaFilterIds,
          state.manufactureFilterId,
          state.searchFilter,
          state.statusFilter
        );
        state.planList = res.data;
        state.totalPages = res.meta?.last_page;
      } catch {
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      } finally {
        isDataLoading.value = false;
        nextTick(() => registerDropdown());
      }
    }

    return {
      state,
      getData,
      isDataLoading,
      productToShow,
      resetFilters,
      getPlanProducts,
    };
  },
};
</script>

<style lang="scss" scoped>
.line-separator {
  background-color: var(--main-search-input);
}
</style>

<style>
.application-worker .awesome-table {
  max-height: calc(100vh - 330px);
}
</style>