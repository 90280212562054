<template>
  <section class="depot">
    <div class="d-flex align-items-center justify-content-start mb-4">
      <router-link class="main-tab__title" :to="{ name: 'plan-page' }">
        Производство
      </router-link>
      <router-link
        class="main-tab__title"
        :to="{
          name: 'subtask-group-page',
          params: {
            id: parseInt($route.params.taskid),
            dealid: parseInt($route.params.dealid),
          },
        }"
      >
        Группы
      </router-link>
      <h2 class="main-tab__title active">Подзадачи</h2>
    </div>

    <SubtasksTableWrapper />
  </section>
</template>

<script>
import SubtasksTableWrapper from "@/components/plan/SubtasksTableWrapper.vue";

export default {
  name: "plan-page",
  components: { SubtasksTableWrapper },
  setup() {
    return {};
  },
};
</script>
